import { Suspense, useEffect, useRef, useState } from 'react';
import Header from 'src/components/Header/Header';
import InfiniteBackground from 'src/components/InfiniteBackground/InfiniteBackground';
// import { dataInfiniteBackground } from 'src/components/InfiniteBackground/infiniteBackground.copy';
import Layout from 'src/components/Layout/Layout';
import Intro from 'src/sections/Intro/Intro';
import Slide1 from 'src/sections/Slide1/Slide1';
import Slide2 from 'src/sections/Slide2/Slide2';
import Slide3 from 'src/sections/Slide3/Slide3';
import Slide4 from 'src/sections/Slide4/Slide4';
import Slide5 from 'src/sections/Slide5/Slide5';
import Slide6 from 'src/sections/Slide6/Slide6';
import Slide7 from 'src/sections/Slide7/Slide7';
import Slide8 from 'src/sections/Slide8/Slide8';
import { useRegisterEffect } from 'src/hooks/useRegisterEffect';
import Noise from 'src/components/Noise/Noise';
import { useRegisterBlurEffect } from 'src/hooks/useRegisterBlurEffect';
import useObserver from 'src/hooks/useObserver';
import { Modal } from 'src/components/Modal/Modal';
import axios from 'axios';
import Loading from 'src/components/Loading/Loading';
import useVisitorDetection from 'src/hooks/useVisitorDetection';
import CookiesModal from 'src/components/CookiesModal/CookiesModal';
import { useGenerationStore } from 'src/hooks/useGenerationStore';
import useSlideByParams from 'src/hooks/useSlideByParams';



import tempData from 'src/data/api2.json';


const HomePage = (props: any) => {
    const { activeSlide } = useGenerationStore();
    const { getCookie, setCookie } = useVisitorDetection();
    useEffect(() => {
        const checkVisitor = () => {
            const urlParams = new URLSearchParams(window.location.search);
            const slide8Param = urlParams.has('slide8');
            const introParam = urlParams.has('intro');
            const introDisplayParam = urlParams.has('display_intro=1');
            if (introParam === true || introDisplayParam === true) {
                // Clear gg_skip cookie
                setCookie('gg_skip', 'false', 0);
            }
            else {
                const alwaysSkip = getCookie('gg_skip');
                if (alwaysSkip) {
                    if (!slide8Param) {
                        window.location.href = '/?slide8';
                    }
                }
            }
        };

        checkVisitor();
    }, [getCookie, setCookie]);


    useSlideByParams();

    useEffect(() => {
        const visited = getCookie('gg_introvisited');
        if (!visited && activeSlide === 8) {
            setCookie('gg_introvisited', 'true', 365);
        }
    }, [activeSlide, getCookie, setCookie]);

    const mainRef = useRef<HTMLDivElement>(null);

    useRegisterEffect();
    useRegisterBlurEffect();
    useVisitorDetection();

    const [data, setData] = useState<any>([]);
    const [loading, setLoading] = useState(true);
    // const [error, setError] = useState(null);
    const [dataInfiniteBg, setDataInfiniteBg] = useState<any>(null);
    const [allImagesLoaded, setAllImagesLoaded] = useState(false); // Track if all images are loaded
    const [showImages, setShowImages] = useState(false);



    useEffect(() => {
        const username = process.env.REACT_APP_API_USERNAME || '';
        const password = process.env.REACT_APP_API_PASSWORD || '';
        // temporary disable api call
        const url = `${process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_URL : '/api'}`;
        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await axios.get(url);
                setData(response.data);
                setTimeout(() => {
                    setLoading(false);
                }, 1000);
            } catch (error: any) {
                setLoading(false);
            }
        };

        fetchData()

        // Clean up function to cancel the request if the component unmounts
        return () => { };
    }, []);

    useEffect(() => {
        if (data && data.slides) {
            const background = data.slides[0].background;
            const length = background.length;
            const columnCount = window.innerWidth < 768 ? 3 : 5;
            const columnLength = Math.ceil(length / columnCount);
            const dataInfiniteBackground = Array.from({ length: columnCount }, (_, i) => ({
                name: `column ${i + 1}`,
                images: background.slice(i * columnLength, (i + 1) * columnLength).map((src: string, index: number) => ({
                    src,
                    alt: `image ${i * columnLength + index + 1}`,
                    width: 450,
                    height: 450
                }))
            }));

            setDataInfiniteBg(dataInfiniteBackground);

            // Check if all images are loaded
            const loadImagePromises = dataInfiniteBackground.flatMap(column => column.images.map((image: any) => {
                return new Promise(resolve => {
                    const img = new Image();
                    img.onload = () => resolve(true);
                    img.onerror = () => resolve(false);
                    img.src = image.src;
                });
            }));
            Promise.all(loadImagePromises).then(results => {
                setAllImagesLoaded(results.every(result => result));
                setShowImages(true);
            });
        }
    }, [data]);

    useObserver();

    // Render components only when all images are loaded
    if (loading || !allImagesLoaded) {
        return <Loading />;
    }

    return (
        <Layout>
            <Suspense fallback={<div>Loading...</div>}>
                <h1 className="sr-only">Gaza Genocide Intro</h1>
                <Header />
                <main className='relative overflow-hidden transition-all bg-black dark:bg-white' ref={mainRef}>
                    <Suspense fallback={<div>Loading...</div>}>

                        {/* {
                            dataInfiniteBg && showImages && (
                                <InfiniteBackground data={dataInfiniteBg} className={` ${allImagesLoaded ? '' : 'hidden'}`} />
                            )
                        } */}

                        {
                            data && <InfiniteBackground data={dataInfiniteBg} />
                        }
                        <Intro />
                        <div className="z-10 slide-wrapper ">
                            {
                                data && data.slides && (
                                    <>
                                        <Slide1 data={data.slides[0]} />
                                        <Slide2 data={data.slides[1]} />
                                        <Slide3 data={data.slides[2]} />
                                        <Slide4 data={data.slides[3]} />
                                        <Slide5 data={data.slides[4]} />
                                        <Slide6 data={data.slides[5]} />
                                        <Slide7 data={data.slides[6]} />
                                        <Slide8 data={data.slides[7]} blocks={data.blocks.toc_block} />
                                    </>
                                )
                            }
                        </div>
                    </Suspense>
                </main>
                <Noise />
                <Modal />
                <CookiesModal />
            </Suspense>
        </Layout>
    );
}

export default HomePage; 