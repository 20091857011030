import { useGSAP } from "@gsap/react";
import { useEffect, useRef } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useTextTransitions } from "src/hooks/useTextTransitions";
import { useGenerationStore } from "src/hooks/useGenerationStore";
import { Tooltip } from "src/components/Tooltip/Tooltip";
import parser from 'html-react-parser'
import useImageTextDiff from "src/hooks/useImageTextDiff";

const Slide4 = ({ data }: { data: any }) => {
    const mainRef = useRef<HTMLDivElement>(null);
    const refText = useRef<HTMLDivElement>(null);
    const refImage = useRef<HTMLDivElement>(null);
    const { setTimelineSlide4 } = useGenerationStore();

    useTextTransitions({ mainRef });

    // this hook handle image and text diff, so they will not overlap
    useImageTextDiff({
        refImage,
        refText,
    });

    useGSAP(() => {
        gsap.registerPlugin(ScrollTrigger);
        const main = mainRef.current;
        if (!main) return;


        const allText = main.querySelectorAll('.lineChild');
        const tl = gsap.timeline({ paused: true })
            .from(allText, {
                y: '100%',
                duration: 1,
                stagger: 0.1,
                ease: 'power4.out',
            }, 0)


        const tlImage = gsap.timeline({
            paused: true,
            delay: 1,
        }).from('[data-image]', {
            clipPath: 'inset(100% 0 0 0)',
            duration: 2,
            ease: 'power3.out',
        })
            .from('[data-image-bg]', {
                blur: 12,
                duration: 2.5,
                ease: 'power4.inOut'
            }, 1)
            .from('[data-image-overlay]', {
                duration: 3,
                x: '-30%',
                ease: 'power4.inOut'
            }, 0);

        const elementImage = main.querySelector('[data-image]');


        setTimelineSlide4({
            tl,
            tlImage,
            elementImage
        });

    }, { scope: mainRef })

    return (
        <section className="relative flex items-start lg:items-center slide " ref={mainRef} tabIndex={4}>
            <div className="absolute bottom-0 left-0 w-full lg:w-1/2" ref={refImage}>
                <div className="relative overflow-hidden image-double" data-image >
                    <img src="/gg-slider-assets/images/blurred/woman_running_away_from_wreckage_with_baby_in_hands.jpg" alt="Slide4" className="relative object-cover w-full h-full" data-image-bg />
                    <img src="/gg-slider-assets/images/blurred/woman_running_away_from_wreckage_with_baby_in_hands-overlay.png" alt="Slide4" className="absolute top-0 object-cover w-full h-full" data-image-overlay />
                </div>
            </div>
            <div className="flex flex-col container-full lg:flex-row" ref={refText}>
                <div className="w-full pt-25 pl-0 ml-auto lg:pt-0 lg:pl-20 lg:w-1/2">
                    <h2 className="~mb-3/5 text-white" data-text-animation>{data.title}</h2>
                    <h3 className="~pr-5/10 text-white ~mb-3/20 " data-text-animation>
                        {parser(data.subheading)}
                        <Tooltip id="tooltip-slide-4" content={data.citation} />
                    </h3>
                    <p className="max-w-md font-light text-white commentary" data-text-animation>{data.commentary}</p>
                </div>
            </div>
        </section>
    );
}

export default Slide4;