// import NiceModal, { useModal } from '@ebay/nice-modal-react';
import React from 'react';
import { useTheme } from 'src/contexts/ThemeProvider';
// import { useGenerationStore } from 'src/hooks/useGenerationStore';
import Logo from '../Logo/Logo';
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
} from "react-share";
export const Modal = () => {
    const mainRef = React.useRef<HTMLDivElement>(null);
    // const { setIsOpenModal, isOpenModal } = useGenerationStore();
    const { onToggleModal, isModalOpen } = useTheme();

    const shareUrl = 'https://gaza-genocide.org';

    // const closeModal = () => {
    //     setIsOpenModal(false);
    // }

    return (

        <div className={`${isModalOpen ? 'flex' : 'hidden'} bg-black/50 w-screen h-screen fixed top-0 left-0 items-center justify-center z-9999`} ref={mainRef}>
            <div className="w-100 bg-white shadow-[rgba(255,_255,_255,_0.25)_0px_0px_200px_50px]">
                <div className="relative head">
                    <button className="absolute z-10 flex right-5 top-5 group" onClick={onToggleModal}>
                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" className="transition-transform duration-500 ease-out transform group-hover:rotate-180"
                        >
                            <path d="M20 1.7815L18.7185 0.5L10 9.21825L1.28125 0.5L0 1.7815L8.71875 10.4998L0 19.218L1.28125 20.4995L10 11.7812L18.7185 20.4995L20 19.218L11.2817 10.4998L20 1.7815Z" fill="white" />
                        </svg>
                    </button>
                    <div className='before:absolute before:top-0 before:left-0 before:w-full before:h-full before:bg-radient-ellipse-c from-[rgba(0,0,0,0.5)]  to-black to-90% '>
                        <img src="./gg-slider-assets/images/popup_bg.jpg" alt="pop up modal" className="object-cover w-full h-full" />
                    </div>
                    <p className="absolute transform -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2">
                        <Logo isWhite={true} />
                    </p>
                </div>
                <div className="body bg-white px-7.5 py-5 ">
                    <div className="div border-b border-b-borders  pb-6.25">
                        <p className="text-5.75 text-black font-light mb-2.5">Together, we must bring awareness to the genocide in Gaza. </p>
                        <p className="text-5.75 text-black font-light mb-6.25">#gazagenocide</p>
                        <a href='https://gazagenocide.com' target='_blank' rel='noreferrer' className="relative text-base font-light text-black before:absolute before:w-full before:h-px before:bg-black dark:before:bg-white before:bottom-0 before:left-0 before:transform before:scale-x-0 hover:before:scale-x-100 before:origin-left before:transition-all ">gazagenocide.com</a>
                    </div>
                </div>
                <div className="footer pb-7.5 px-7.5">
                    <div className="flex justify-between">
                        <p className="text-base font-medium text-black">Share Message on</p>
                        <div className="flex gap-5">
                            <FacebookShareButton
                                url={shareUrl}
                                className='transition-all transform hover:-translate-y-1 hover:scale-110'
                            >
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.5 6H13.5C12.9477 6 12.5 6.44772 12.5 7V10H16.5C16.6137 9.99748 16.7216 10.0504 16.7892 10.1419C16.8568 10.2334 16.8758 10.352 16.84 10.46L16.1 12.66C16.0318 12.8619 15.8431 12.9984 15.63 13H12.5V20.5C12.5 20.7761 12.2761 21 12 21H9.5C9.22386 21 9 20.7761 9 20.5V13H7.5C7.22386 13 7 12.7761 7 12.5V10.5C7 10.2239 7.22386 10 7.5 10H9V7C9 4.79086 10.7909 3 13 3H16.5C16.7761 3 17 3.22386 17 3.5V5.5C17 5.77614 16.7761 6 16.5 6Z" fill="#242833" />
                                </svg>
                            </FacebookShareButton>
                            <LinkedinShareButton
                                url={shareUrl}
                                className='transition-all transform hover:-translate-y-1 hover:scale-110'>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M5 3H19C20.1046 3 21 3.89543 21 5V19C21 20.1046 20.1046 21 19 21H5C3.89543 21 3 20.1046 3 19V5C3 3.89543 3.89543 3 5 3ZM8 18C8.27614 18 8.5 17.7761 8.5 17.5V10.5C8.5 10.2239 8.27614 10 8 10H6.5C6.22386 10 6 10.2239 6 10.5V17.5C6 17.7761 6.22386 18 6.5 18H8ZM7.25 9C6.42157 9 5.75 8.32843 5.75 7.5C5.75 6.67157 6.42157 6 7.25 6C8.07843 6 8.75 6.67157 8.75 7.5C8.75 8.32843 8.07843 9 7.25 9ZM17.5 18C17.7761 18 18 17.7761 18 17.5V12.9C18.0325 11.3108 16.8576 9.95452 15.28 9.76C14.177 9.65925 13.1083 10.1744 12.5 11.1V10.5C12.5 10.2239 12.2761 10 12 10H10.5C10.2239 10 10 10.2239 10 10.5V17.5C10 17.7761 10.2239 18 10.5 18H12C12.2761 18 12.5 17.7761 12.5 17.5V13.75C12.5 12.9216 13.1716 12.25 14 12.25C14.8284 12.25 15.5 12.9216 15.5 13.75V17.5C15.5 17.7761 15.7239 18 16 18H17.5Z" fill="#242833" />
                                </svg>
                            </LinkedinShareButton>
                            <TwitterShareButton
                                url={shareUrl}
                                className='transition-all transform hover:-translate-y-1 hover:scale-110'>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17.1747 4H19.9361L13.9048 10.7769L21 20H15.4459L11.0926 14.4077L6.11734 20H3.35202L9.80183 12.75L3 4H8.69491L12.6258 9.11154L17.1747 4ZM16.2047 18.3769H17.734L7.8618 5.53846H6.21904L16.2047 18.3769Z" fill="#242833" />
                                </svg>
                            </TwitterShareButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
