import { set } from "lodash";
import React, { useContext, useEffect } from "react";
import { useAudio } from "src/hooks/useAudio";
import { useGenerationStore } from "src/hooks/useGenerationStore";

interface ThemeContextProps {
    onToggleSound: () => void;
    progress: number;
    setProgressBar: (value: number) => void;
    onClickStartSlide: () => void;
    startSlide: boolean;
    activeSlide: number;
    // isStart: boolean;
    isModalOpen: boolean;
    setIsModalOpen: (value: boolean) => void;
    onToggleModal: () => void;
    // setIsStart: (value: boolean) => void;
    onClickSkip: () => void;

}

const ThemeContext = React.createContext<ThemeContextProps | undefined>(undefined);

interface ThemeProviderProps {
    children: React.ReactNode;
}

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
    const { playAudio, pauseAudio } = useAudio();
    const [progress, setProgress] = React.useState(1);
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const { sound, setIsToggleSound } = useGenerationStore();
    const refIsToggleSound = React.useRef(true);

    useEffect(() => {
        const unsub = useGenerationStore.subscribe((state, prevState) => {
            // refSound.current = state.sound;
            refIsToggleSound.current = state.isToggleSound;
        });
        return () => {
            unsub();
        };
    }, []);


    const onToggleSound = () => {
        // console.log(refIsToggleSound.current)
        // console.log('first,',refIsToggleSound.current,sound, sound?.playing())
        if (refIsToggleSound.current) {
            if (sound && sound?.playing()) {
                // console.log('pause')
                pauseAudio();
            }
        } else {
            if(!sound){ 
                // console.log('play')
                playAudio();
            }
            else if (sound && !sound?.playing()) {
                // console.log('play')
                playAudio();
            }
        }
        setIsToggleSound(!refIsToggleSound.current);
    };

    const onToggleModal = () => {
        setIsModalOpen(!isModalOpen);
    }

    const setProgressBar = (slide: number) => {
        setProgress(slide);
    }

    // HANDLE START SLIDE
    const [startSlide, setStartSlide] = React.useState(false);
    const [activeSlide, setActiveSlide] = React.useState(0);
    // const [isStart, setIsStart] = React.useState(false);

    const { isStart, setIsStart, setIsStartSkip } = useGenerationStore();

    const onClickStartSlide = () => {
        if (refIsToggleSound.current === false) {
            setIsToggleSound(false);
        } else {
            setIsToggleSound(true);
            playAudio();
        }
        setTimeout(() => {
            setStartSlide(true);
            setIsStart(true);
        }, 500)
        // setActiveSlide(1);
    };

    const onClickSkip = () => {
        setIsStartSkip(true);
    }

    const value = {
        progress,
        setProgressBar,
        onClickStartSlide,
        startSlide,
        activeSlide,
        // isStart,
        onToggleModal,
        isModalOpen,
        setIsModalOpen,
        // setIsStart,
        onToggleSound,
        onClickSkip,
    };


    return (
        <ThemeContext.Provider value={value}>
            {children}
        </ThemeContext.Provider>
    );
};

export const useTheme = () => {
    const context = useContext(ThemeContext);
    if (!context) {
        throw new Error('useTheme must be used within a ThemeProvider');
    }
    return context;
};
