import Toggle from "src/components/Toggle/Toggle";
import { useTheme } from "src/contexts/ThemeProvider";
import { useGenerationStore } from "src/hooks/useGenerationStore";

const Intro = () => {
    const { onClickStartSlide } = useTheme();
    const { isStart, isStartWithParam, isStartSkip , isToggleSound} = useGenerationStore();

    const textWithSound = isToggleSound ? 'Enter with sound!' : 'Enter!';

    return (
        <section className={`fixed flex items-center justify-center w-full h-screen ${isStart || isStartWithParam || isStartSkip ? 'opacity-0 pointer-events-none' : ''} transition-all duration-500`}>
            <div className="flex flex-col items-center text-center">
                <div className="mb-7">
                    <Toggle />
                </div>
                <p className="font-bold text-lg mb-7.5 text-white font-lexend px-5">Turn on the sound to get the full experience</p>
                <button className="relative text-base text-white transition-all font-lexend before:absolute before:w-full before:h-px before:bg-white dark:before:bg-black before:bottom-0 before:left-0 before:origin-left before:transition-all animation-line-horizontal " onClick={onClickStartSlide}>
                    <span >{textWithSound}</span>
                </button>
            </div>
        </section>
    );
}


export default Intro;