import { useEffect, useRef } from "react";
import Lottie, { LottieRefCurrentProps } from 'lottie-react';
import gazaMapLottie from 'src/lottie/gaza_map_topo_2.json'
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/all";
import gsap from "gsap";
import { useTextTransitions } from "src/hooks/useTextTransitions";
import { useGenerationStore } from "src/hooks/useGenerationStore";
import { Tooltip } from "src/components/Tooltip/Tooltip";
import parser from 'html-react-parser'

const Slide3 = ({ data }: { data: any }) => {
    const mainRef = useRef<HTMLDivElement>(null);
    const refLottie = useRef<LottieRefCurrentProps>(null);
    const { setTimelineSlide3, setLottie } = useGenerationStore();

    useTextTransitions({ mainRef });

    useEffect(() => {
        setLottie(refLottie.current);
    }, [refLottie, setLottie]);

    useGSAP(() => {
        gsap.registerPlugin(ScrollTrigger);
        const main = mainRef.current;
        if (!main) return;

        // const elementWithDataCounter = main.querySelector('[data-counter]');
        // const end = elementWithDataCounter?.getAttribute('data-counter');
        const allText = main.querySelectorAll('.lineChild');
        // const tooltip = main.querySelector('.tooltip');

        const tl = gsap
            .timeline({
                paused: true,
            })
            // .counter("[data-counter]", { end: end, ease: "linear", duration: 1 })
            .fromTo(allText, {
                y: '100%',
            }, {
                y: '0%',
                duration: 1,
                stagger: 0.1,
                ease: 'power4.out',
            }, 0)
            // .fromTo(tooltip, {
            //     autoAlpha: 0
            // },
            //     {
            //         autoAlpha: 1,
            //         duration: 1,
            //         ease: 'power4.out',
            //     },
            // )
            .from('[data-lottie]', {
                opacity: 0,
                duration: 1,
                ease: 'power4.out',
            }, 0)

        setTimelineSlide3(tl);
    }, { scope: mainRef })


    return (
        <section className="relative flex items-center slide " ref={mainRef} tabIndex={3}>
            <div className="flex flex-col container-full lg:flex-row lg:items-center">
                <div className="w-full lg:w-1/2 pb-15 lg:pb-0 pt-25 lg:pt-0">
                    <div className="">
                        <h2 className=" text-white ~mb-2/5" data-text-animation>{data.title}</h2>
                    </div>
                    <div className="~mb-3/20">
                        <h3 className="max-w-xl text-white " data-text-animation>{parser(data.subheading)}</h3>

                    </div>
                    <p className="~pr-5/10 text-white commentary" data-text-animation>{data.commentary}
                        <Tooltip id="tooltip-slide-3" content={data.citation} />
                    </p>
                    {/* <span className="inline-block tooltip">
                    </span> */}
                </div>
                <div className="relative flex w-full lg:w-1/2" data-lottie>
                    {/* <Gaza /> */}
                    <Lottie
                        animationData={gazaMapLottie}
                        autoplay={false}
                        lottieRef={refLottie}
                        loop={false}
                        className="w-full h-[50vh] lg:h-[100vh] flex items-center py-10 lg::py-0"
                        controls={true}
                    />
                    <div className="absolute top-0 left-0 w-full h-full map-grad">

                    </div>
                </div>
            </div>
        </section>
    );
}

export default Slide3;