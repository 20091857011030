import { useGSAP } from "@gsap/react";
import { useRef } from "react";
import { useTextTransitions } from "src/hooks/useTextTransitions";
import gsap from "gsap";
import { useGenerationStore } from "src/hooks/useGenerationStore";

const Slide1 = ({ data }: { data: any }) => {
    const slide1Ref = useRef<HTMLDivElement>(null);
    useTextTransitions({ mainRef: slide1Ref });
    const { isStart, isStartWithParam, isStartSkip } = useGenerationStore();
    const { setTimelineSlide1, setIsAnimating, setActiveSlide, setMasterTimeline, setProgress } = useGenerationStore();

    useGSAP(() => {
        if (!slide1Ref.current) return;

        const main = slide1Ref.current;

        const tl = gsap.timeline({
            paused: true,
            onComplete: () => {
                setIsAnimating(false);
            },
        });

        const allText = main.querySelectorAll('.lineChild');
        if (allText.length > 0) {
            tl.fromTo(allText, {
                y: '100%',
            }, {
                y: '0%',
                duration: 1,
                stagger: 0.1,
                ease: 'power4.out',
            });
        }

        if (isStart && isStartWithParam === false && isStartSkip === false) {
            setIsAnimating(true);
            slide1Ref.current.classList.add('active');
            setActiveSlide(1);
            setProgress(1);

            setTimeout(() => {
                if (tl) {
                    tl.timeScale(1.5).play();
                }
            }, 2500);
        }

        // SetTimeline
        const tlMaster = gsap.timeline();
        setMasterTimeline(tlMaster);
        setTimelineSlide1(tl);


    }, { scope: slide1Ref, dependencies: [isStart, isStartWithParam, isStartSkip] });

    return (
        <section className="relative flex items-center slide" ref={slide1Ref} tabIndex={1}>
            <div className="container-full">
                <p className="font-semibold text-white kicker font-lora" data-text-animation>{data.kicker}</p>
                <h2 className="font-bold text-white main-title" data-text-animation>{data.title}</h2>
                <p className="max-w-3xl font-light text-white commentary" data-text-animation>{data.commentary}</p>
            </div>
        </section>
    );
};

export default Slide1;
