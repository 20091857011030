import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';
import { Howl } from 'howler';
interface GenerationStore {
	isAnimating: boolean;
	setIsAnimating: (isAnimating: boolean) => void;
	timelineSlide1: any;
	timelineSlide2: any;
	timelineSlide3: any;
	timelineSlide4: any;
	timelineSlide5: any;
	timelineSlide6: any;
	timelineSlide7: any;
	timelineSlide8: any;
	tlInfiniteBgArray: any;
	setTimelineSlide1: (timelineSlide1: any) => void;
	setTimelineSlide2: (timelineSlide2: any) => void;
	setTimelineSlide3: (timelineSlide3: any) => void;
	setTimelineSlide4: (timelineSlide4: any) => void;
	setTimelineSlide5: (timelineSlide5: any) => void;
	setTimelineSlide6: (timelineSlide6: any) => void;
	setTimelineSlide7: (timelineSlide7: any) => void;
	setTimelineSlide8: (timelineSlide8: any) => void;
	setTlInfiniteBgArray: (tlInfiniteBgArray: any) => void;
	activeSlide: number;
	setActiveSlide: (slideActive: number) => void;
	masterTimeline: gsap.core.Timeline | null;
	setMasterTimeline: (masterTimeline: any) => void;
	tlInfiniteBg: any;
	setTlInfiniteBg: (tlInfiniteBg: any) => void;
	progress: number;
	setProgress: (progressBar: number) => void;
	lottie: any;
	setLottie: (lottie: any) => void;
	isOpenModal: boolean;
	setIsOpenModal: (isOpenModal: boolean) => void;
	dataGlobal: any;
	setDataGlobal: (dataGlobal: any) => void;
	isVisited: boolean;
	setIsVisited: (isVisited: boolean) => void;
	isAlwaysSkip: boolean;
	setIsAlwaysSkip: (isAlwaysSkip: boolean) => void;
	hoverProgress: number;
	setHoverProgress: (hoverProgress: number) => void;
	params: any;
	setParams: (params: any) => void;
	isStart: boolean;
	setIsStart: (isStart: boolean) => void;
	isStartWithParam: boolean;
	setIsStartWithParam: (isStartWithParam: boolean) => void;
	isStartSkip: boolean;
	setIsStartSkip: (isStartSkip: boolean) => void;
	isToggleSound: boolean;
	setIsToggleSound: (isToggleSound: boolean) => void;
	sound: Howl | null;
	setSound: (sound: Howl | null) => void;
	isChangingSlide: boolean;
	setIsChangingSlide: (isChangingSlide: boolean) => void;
}

export const useGenerationStore = create<GenerationStore>((set) => ({
	isAnimating: false,
	setIsAnimating: (isAnimating) => {
		set({ isAnimating });
	},
	lottie: null,
	setLottie: (lottie) => set({ lottie }),
	timelineSlide1: null,
	setTimelineSlide1: (timelineSlide1) => set({ timelineSlide1 }),
	timelineSlide2: null,
	setTimelineSlide2: (timelineSlide2) => set({ timelineSlide2 }),
	activeSlide: 0,
	setActiveSlide: (activeSlide) => {
		set({ activeSlide });
	},
	masterTimeline: null,
	setMasterTimeline: (masterTimeline) => set({ masterTimeline }),
	tlInfiniteBg: null,
	setTlInfiniteBg: (tlInfiniteBg) => set({ tlInfiniteBg }),
	progress: 0,
	setProgress: (progress) => set({ progress }),
	timelineSlide3: null,
	setTimelineSlide3: (timelineSlide3) => set({ timelineSlide3 }),
	timelineSlide4: null,
	setTimelineSlide4: (timelineSlide4) => set({ timelineSlide4 }),
	timelineSlide5: null,
	setTimelineSlide5: (timelineSlide5) => set({ timelineSlide5 }),
	timelineSlide6: null,
	setTimelineSlide6: (timelineSlide6) => set({ timelineSlide6 }),
	timelineSlide7: null,
	setTimelineSlide7: (timelineSlide7) => set({ timelineSlide7 }),
	timelineSlide8: null,
	setTimelineSlide8: (timelineSlide8) => set({ timelineSlide8 }),
	//modal subscribe state
	isOpenModal: false,
	setIsOpenModal: (isOpenModal) => set({ isOpenModal }),
	//infinite background
	tlInfiniteBgArray: null,
	setTlInfiniteBgArray: (tlInfiniteBgArray) => set({ tlInfiniteBgArray }),
	dataGlobal: null,
	setDataGlobal: (dataGlobal) => set({ dataGlobal }),
	isVisited: false,
	setIsVisited: (isVisited) => set({ isVisited }),
	isAlwaysSkip: false,
	setIsAlwaysSkip: (isAlwaysSkip) => set({ isAlwaysSkip }),
	hoverProgress: 0,
	setHoverProgress: (hoverProgress) => set({ hoverProgress }),
	params: null,
	setParams: (params) => set({ params }),
	isStart: false,
	setIsStart: (isStart) => set({ isStart }),
	isStartWithParam: false,
	setIsStartWithParam: (isStartWithParam) => set({ isStartWithParam }),
	isStartSkip: false,
	setIsStartSkip: (isStartSkip) => {
		set({ isStartSkip });
	},
	isToggleSound: true,
	setIsToggleSound: (isToggleSound) => set({ isToggleSound }),
	sound: null,
	setSound: (sound) => set({ sound }),
	isChangingSlide: false,
	setIsChangingSlide: (isChangingSlide) => set({ isChangingSlide }),
}));
