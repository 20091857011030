import { useEffect, useState } from "react";
import { useGenerationStore } from "src/hooks/useGenerationStore";
import useVisitorDetection from "src/hooks/useVisitorDetection";
// import useGoToSlides from "src/hooks/useGotoSlides";
import React from "react";

import useGoToSlides from "src/hooks/useGotoSlides";

const CookiesModal = () => {
    const { activeSlide } = useGenerationStore();
    const { getCookie, setCookie } = useVisitorDetection();
    const [isVisited, setIsVisited] = useState(false);
    const visited = getCookie('gg_introvisited');
    const { handleClick } = useGoToSlides();

    useEffect(() => {
        visited === 'true' && activeSlide === 0 ? setIsVisited(true) : setIsVisited(false);

    }, [activeSlide, visited]);

    const handleViewIntro = () => {
        setIsVisited(false);
    };

    const handleAlwaysSkip = () => {
        setCookie('gg_skip', 'true', 15);
        handleClick(8)();
    };

    return (
        <div className={`${isVisited ? 'flex' : 'hidden'} fixed w-[calc(100%_-_40px)] md:w-full flex-col bottom-10 left-1/2 transform -translate-x-1/2 md:translate-x-0 right-auto md:left-auto md:right-10 z-9999 bg-white max-w-[500px] p-7.5 gap-6 shadow-lg`}>
            <h5 className="font-lora">Hello again!</h5>
            <p className="font-lexend font-light leading-1.4">You’ve already been here and seen the introduction. Would you like to watch it again?</p>
            <div className="flex-col flex md:flex-row gap-3.75">
                <button className="px-5 py-3.5 border-primary border hover:bg-primary hover:text-white transition-all font-medium" onClick={handleViewIntro}>
                    <span className="font-lexend text-nowrap">View intro</span>
                </button>
                <button className="px-5 text-center py-3.5 border-primary border hover:bg-primary hover:text-white transition-all font-medium" onClick={() => handleClick(8)()}>
                    <span className="font-lexend  text-nowrap">Skip this time</span>
                </button>
                <button className="text-center px-5 py-3.5 bg-primary hover:bg-secondary font-medium " onClick={handleAlwaysSkip} >
                    <span className="text-white font-lexend text-nowrap" >Skip always</span>
                </button>
            </div>
        </div>
    );
};

export default CookiesModal;
