import React, { useEffect } from 'react';
import { debounce } from 'lodash';

const useImageTextDiff = ({
	refImage,
	refText,
}: {
	refImage: React.RefObject<HTMLDivElement>;
	refText: React.RefObject<HTMLDivElement>;
}) => {
	useEffect(() => {
		const image = refImage.current;
		const text = refText.current;
		if (!image || !text) return;

		const offset = 10;


		const handleResize = debounce(() => {
			image.style.transform = `translateY(0px)`;

			if (window.innerWidth < 1024) {
				const bbText = text.getBoundingClientRect();
				const bbImage = image.getBoundingClientRect();

				if (bbText.bottom >= bbImage.top) {
					const diff = bbText.bottom - bbImage.top;
					image.style.transform = `translateY(${diff + offset}px)`;
				} else {
					image.style.transform = `translateY(0px)`;
				}
			} else {
				image.style.transform = `translateY(0px)`;
			}
		},  0); 

		handleResize();
		window.addEventListener('resize', handleResize);

		// Cleanup function
		return () => {
			window.removeEventListener('resize', handleResize);
			handleResize.cancel(); 
		};
	}, [refImage, refText]);
};

export default useImageTextDiff;
