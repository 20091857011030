import { useGSAP } from "@gsap/react";
import { useRef } from "react";
import { useTextTransitions } from "src/hooks/useTextTransitions";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import { useGenerationStore } from "src/hooks/useGenerationStore";
import parser from 'html-react-parser'

interface Slide7Props {
    data: any;
}

const Slide7: React.FC<Slide7Props> = ({ data }) => {
    const mainRef = useRef<HTMLDivElement>(null);
    const { setTimelineSlide7 } = useGenerationStore();
    useTextTransitions({ mainRef });

    useGSAP(() => {
        gsap.registerPlugin(ScrollTrigger);
        const main = mainRef.current;
        if (!main) return;

        const allText = main.querySelectorAll('.lineChild');

        const image = main.querySelector('[data-image]');
        const tl = gsap.timeline({ paused: true })


        tl.fromTo(allText, {
            y: '100%',
        }, {
            y: '0%',
            duration: 1,
            stagger: 0.1,
            ease: 'power4.out',
        }, 0)

        const tlImage = gsap.timeline({
            paused: true,
        });
        tlImage.from(image, {
            clipPath: 'inset(100% 0 0 0)',
            duration: 1,

        }, 0);

        const tlImageZ = gsap.timeline({ paused: true });
        tlImageZ.to(image, { z: -100 * 1, y: 0, zIndex: 100 - 1 });

        setTimelineSlide7({
            tl,
            tlImage,
            tlImageZ
        });

    }, { scope: mainRef })
    return (
        <section className="relative  flex items-start justify-center slide" ref={mainRef} tabIndex={7}>
            <div className="relative flex flex-col justify-end h-full text-center container-full max-w-[1600px] mx-auto pt-25">
                <p className="mb-2.5 kicker text-white" data-text-animation >{data.kicker}</p>
                <div className="flex ~mb-3/12.5">
                    <div className="w-full max-w-3xl mx-auto ">
                        <h3 className="text-white" data-text-animation>{parser(data.subheading)}</h3>
                    </div>
                </div>

                <div className="slider relative left-0 bottom-0 w-full  prespective-1000 h-[60vh]">
                    <div className="absolute top-0 left-0 w-full h-full slide-item" data-image>
                        <img src="/gg-slider-assets/images/march-for-gaza.jpeg" alt="Slide1" className="relative object-cover w-full h-full" />
                    </div>
                </div>
            </div>
        </section>
    );
}


export default Slide7;