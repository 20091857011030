import React from "react";
import SplitType from "split-type";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import { debounce, words } from "lodash";
import SplitText from "gsap/SplitText";
interface TextTransitionsProps {
    mainRef: React.RefObject<HTMLDivElement>;
}

export const useTextTransitions = ({ mainRef }: TextTransitionsProps) => {
    let split: SplitType[] = [];
    let newSplit: SplitType[] = [];
    let child: SplitText;
    let parent: SplitText;

    useGSAP(() => {
        gsap.registerPlugin(ScrollTrigger, SplitText);
        if (!mainRef.current) return;

        const allDataTextAnimation = mainRef.current.querySelectorAll('[data-text-animation]');

        // child = new SplitText(allDataTextAnimation, { type: "words",tag:"span", reduceWhiteSpace:true,wordsClass: "lineChild will-change-transform !inline-block items-center",lineThreshold:1 });
        // parent = new SplitText(allDataTextAnimation, { type: "lines", tag:"span", linesClass: "lineParent overflow-y-hidden !inline-block" ,lineThreshold:1});


        parent = new SplitText(allDataTextAnimation, { type: "words", tag: "div", wordsClass: "lineParent overflow-y-hidden !inline-block", lineThreshold: 0.1 });
        child = new SplitText(allDataTextAnimation, { type: "words", tag: "div", reduceWhiteSpace: true, wordsClass: "lineChild will-change-transform items-center", lineThreshold: 0.5 });

        const tooltip = mainRef.current.querySelector(".tooltip-parent");

        const target = mainRef.current.querySelectorAll(".lineParent:not(.tooltip-parent)");
            
        // move tooltip into target
        if(tooltip){
            target[target.length - 1].appendChild(tooltip);
        }

        const handleResize = debounce(() => {
            if (child.lines.length > 0) {
                split.forEach((splitInstance) => splitInstance.revert());
                split = [];
            }
            if (parent.lines.length > 0) {
                newSplit.forEach((newSplitInstance) => newSplitInstance.revert());
                newSplit = [];
            }
        }, 300);

        // window.addEventListener("resize", handleResize);

        return () => {
            // window.removeEventListener("resize", handleResize);
            // split = [];
            // newSplit = [];
        };
    }, { scope: mainRef });

    return mainRef;
};
